<template>
  <button
    :class='["btn btn-block", {"btn-primary": !duringSubmission, "btn-info": submitting, "btn-success": submitted}]'
    :disabled='!creatable'
    @click.prevent.stop='$emit("submit")'
    >
      <span v-if='!duringSubmission' key='create'>
        {{ translations.submit_order }}
        <span v-if='showPrice'>
          ({{ order.price | monetize }})
        </span>
      </span>
      <fa-icon
        key='submitting'
        v-else-if='submitting'
        icon='spinner'
        class='fa-spin'
      />
      <span
        key='submitted'
        v-else>
        <fa-icon
          icon='check' />
        {{ translations.submitted }}
      </span>
  </button>
</template>

<script>

export default {
  props: [ "order", "translations" ],
  computed: {
    showPrice() { return this.order.hasItems },

    creatable() { return this.order.creatable },
    submitting() { return this.order.submitting },
    submitted() { return this.order.submitted },
    duringSubmission() { return this.order.duringSubmission },
  }
}


</script>