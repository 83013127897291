import { Client } from "../../api"

export default class CreateOrderAPI extends Client {
  submit(order) {
    const partial_order = this.serializeOrder(order)
    const data = { partial_order }

    return this.post(data)
  }

  serializeOrder(order) {
    return {
      person_id: (order.person ? order.person.id : null),
      items_attributes: order.items.map((item) => this.serializeOrderItem(item))
    }
  }

  serializeOrderItem(order_item) {
    return {
      product_id: order_item.product.id,
      quantity: order_item.quantity
    }
  }
}