import { Controller } from "stimulus"

export default class StepperInputController extends Controller {
  static actions = ["plus", "minus"]
  static targets = ["field"]

  get fieldValue() { return (this.fieldTarget.value != "") ? parseInt(this.fieldTarget.value) : 0 }
  set fieldValue(value) { this.fieldTarget.value = value }

  connect() {
  }

  plus(e) {
    this._stopButtonEvent(e)
    this._applyDelta(1)
  }

  minus(e) {
    this._stopButtonEvent(e)
    this._applyDelta(-1)
  }

  // ===========
  // = Private =
  // ===========

  _stopButtonEvent(e) {
    e.preventDefault()
  }

  _applyDelta(delta) {
    this.fieldValue = this.fieldValue + delta
  }
}