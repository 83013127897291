export function groupBy(list, keyFunction) {
  const groups = new Map();

  list.forEach((item) => {
    const key = keyFunction(item);
    const items = groups.get(key);

    if (!items) {
      groups.set(key, [item])
    } else {
      items.push(item)
    }
  });

  const keys = extractSortedKeysFromMap(groups)
  return keys.map((key) => {
    return { key, items: groups.get(key) }
  })
}

function extractSortedKeysFromMap(map) {
  const it = map.keys()
  const keys = Array.from(it)

  keys.sort()

  return keys
}