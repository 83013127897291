import Vue from 'vue'

import { Menu, Product } from "../models"

export default class MenuFactory {
  createMenuFromProducts(items) {
    const menu = new Menu()

    menu.products = this.createProducts(items)

    return Vue.observable(menu)
  }

  createProducts(product) {
    return product.map((rawProduct) => this.createProduct(rawProduct))
  }

  createProduct(rawProduct) {
    const product = new Product()

    product.id = rawProduct.id
    product.title = rawProduct.title
    product.priceInCents = rawProduct.price_in_cents

    return Vue.observable(product)
  }
}