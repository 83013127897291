import { config, library, dom } from '@fortawesome/fontawesome-svg-core'

import {
  faCheck,
  faCog,
  faExclamationTriangle,
  faGripLines,
  faHandPaper,
  faMinus,
  faMoneyBillWave,
  faPencilAlt,
  faPlus,
  faSpinner,
  faTimes,
  faTimesCircle,
  faTrash,

} from "@fortawesome/free-solid-svg-icons"

// import {
//   faSquare,
// } from '@fortawesome/free-regular-svg-icons'


export function start() {
  library.add(
    faCheck,
    faCog,
    faExclamationTriangle,
    faHandPaper,
    faGripLines,
    faMinus,
    faMoneyBillWave,
    faPencilAlt,
    faPlus,
    faSpinner,
    faTimes,
    faTimesCircle,
    faTrash,

    // faSquare,
  )

  dom.watch()
}