<template>
  <select :class='["custom-select", {"is-valid": value}]'
    v-model='currentValue'
    :disabled='disabled'>
    <option :value='defaultValue'>{{ translations.select_customer }}</option>
    <optgroup
      :label='translations.recent_customers'
      v-if='hasRecentPeople'>
      <option
        v-for='person in recentPeople'
        :key='person.id'
        :value='person.id'>
        {{ person.name }}
      </option>
    </optgroup>
    <optgroup
      v-for='group in groupedPeople'
      :label='group.key'>
      <option
        v-for='person in group.items'
        :key='person.id'
        :value='person.id'>
        {{ person.name }}
      </option>
    </optgroup>
  </select>
</template>

<script>

import { groupBy } from "../../../../utils"

const DEFAULT_VALUE = '_default_'

const personCompare = (a, b) => `${a.name}`.localeCompare(b.name)

export default {
  props: [ 'people', 'value', 'disabled', 'translations' ],
  data() {
    return {
      currentValue: DEFAULT_VALUE,
      defaultValue: DEFAULT_VALUE,
    }
  },
  computed: {
    groupedPeople() {
      const groups = groupBy(this.people, (person) => person.name.substr(0, 1))

      groups.forEach((group) => {
        group.items.sort(personCompare)
      })

      return groups
    },
    recentPeople() {
      const recents = this.people.filter((person) => person.hasOrder )

      recents.sort(personCompare)

      return recents
    },
    hasRecentPeople() { return this.recentPeople.length > 0 },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        const { value } = this

        this.currentValue = value === null ? DEFAULT_VALUE : value.id
      }
    },
    currentValue() {
      this.$emit("input", this.currentValue == DEFAULT_VALUE ? null : this.people.find((person) => person.id == this.currentValue))
    }
  }
}

</script>