import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "container" ]

  cancel(e) {
    e.stopPropagation()
    e.preventDefault()

    const $container = $(this.containerTarget)

    $container.prev().show()
    $container.remove()
  }
}