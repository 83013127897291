<template>
  <a
    ref='button'
    class='menu-item border border-primary text-primary rounded overflow-hidden d-flex flex-column align-items-center justify-content-center p-2'
    @click.native.stop.prevent='$emit("click")'
    >
    <strong class='title mw-100'>{{ product.title }}</strong>
    <small>{{ product.price | monetize }}</small>
  </a>
</template>

<script>

export default {
  props: [ "product" ],
}

</script>