export default class Person {
  constructor() {
    this.id = -1
    this.name = "Unnamed"
    this.hasOrder = false
  }
  get firstName() { return this.name.replace(/\s[^\s]*$/g, "") }
  get lastName() { return this.name.replace(/^.*\s/g, "") }

  get reverseName() { return `${this.lastName} ${this.firstName}` }
}