<template>
  <div
    class='list-group-item'
    >
    <div class='d-flex align-items-center'>
      <strong class='flex-grow-0'>{{ item.quantity }}</strong>
      <div class='flex-grow-1 col'>
        {{ item.title }}
      </div>
      <div class='flex-grow-0'>
        <button
          class='btn btn-danger btn-sm'
          :disabled='disabled'
          @click='decrement'>
          <fa-icon icon='minus' />
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: [ "item", "disabled" ],
  computed: {
    willRemoveOnDecrement() { return this.item.quantity <= 1 },
    decrementIcon() { return this.willRemoveOnDecrement ? "times-circle" : "minus" },
  },
  methods: {
    increment() {
      this.item.increment()
    },
    decrement() {
      this.item.decrement()
    },
    checkNeedsRemoval() {
      this.item.removeIfNecessary()
    },
    handleEnter() {
      this.$refs.input.blur()
    }
  }
}

</script>