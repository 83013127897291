import Vue from "vue"

import OrderItem from "./order_item"

export default class Order {
  constructor() {
    this.items = []
    this.person = null

    this.submitting = false
    this.submitted = false
    this.collectPaymentUrl = null
  }

  get priceInCents() { return this.items.reduce((agg, i) => { return agg + i.priceInCents }, 0) }
  get price() { return this.priceInCents * 0.01 }

  get hasItems() { return this.items.length > 0 }
  get hasPerson() { return this.person != null }

  get clearable() { return (this.hasItems || this.hasPerson) && this.modifiable }
  get duringSubmission() { return this.submitting || this.submitted }
  get creatable() { return this.hasItems && this.hasPerson && !this.duringSubmission }
  get modifiable() { return !this.duringSubmission }

  addProduct(product) {
    const orderItem = this.findItemByProduct(product) || this.createItemByProduct(product)
    orderItem.increment()
  }

  removeItem(item) {
    const idx = this.items.indexOf(item)

    if (idx >= 0) {
      this.items.splice(idx, 1)
    }
  }

  clear() {
    this.person = null
    this.items.splice(0, this.items.length)
  }

  findItemByProduct(product) {
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i]

      if (item.product == product) {
        return item
      }
    }
  }

  createItemByProduct(product) {
    if (!this.modifiable) return;

    const orderItem = Vue.observable(new OrderItem())

    orderItem.product = product
    orderItem.order = this

    this.items.push(orderItem)

    return orderItem
  }
}