export function getLocale() {
  const metaTag = document.head.querySelector("meta[name=rails-locale]")

  let locale;

  if (metaTag) {
    locale = metaTag.getAttribute("value")
  }

  return locale || 'de'
}