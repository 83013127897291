import { expose as exposeJQuery } from "./jquery"
import { install as installMonkeyPatches } from "./monkey-patches"
import { start as startFontAwesome } from "./font-awesome"
import { start as startBootstrap, refresh as refreshBootstrap } from "./bootstrap"
import { start as startVue, refresh as refreshVue } from "./vue"
import { start as startStimulus } from "./stimulus"
import "cocoon"

import "./stylesheets/application.scss"


const App = {
  start() {
    installMonkeyPatches()
    exposeJQuery()

    startFontAwesome()
    startVue()
    startStimulus()
    startBootstrap()
  },

  refresh() {
    refreshVue()
    refreshBootstrap()
  }
}

export function start() {
  window.App = App
  App.start()

  document.addEventListener("turbolinks:load", () => { App.refresh() })
}

