<template>
  <div class='menu-item-grid'>
    <Product
      v-for='product in products'
      :key='product.id'
      :product='product'
      @click.native='$emit("add", product)'
      />
  </div>
</template>

<script>

import Product from "./product"

export default {
  props: [ "menu" ],
  components: { Product },
  computed: {
    products() { return this.menu.products },
  }
}

</script>