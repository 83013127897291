import Vue from 'vue'

import { Person } from "../models"

export default class PersonFactory {
  createPeopleFromData(data) {
    return data.map((raw) => this.createPerson(raw))
  }

  createPerson(raw) {
    const person = new Person()

    person.id = raw.id
    person.name = raw.name
    person.hasOrder = raw.has_order

    return Vue.observable(person)
  }
}