export default class OrderItem {
  constructor() {
    this.quantity = 0
    this.product = undefined
  }

  get id() { return this.product.id }
  get title() { return this.product.title }
  get priceInCents() { return this.product.priceInCents * this.quantity }
  get price() { return this.priceInCents * 0.01 }

  increment() {
    this.quantity++
  }

  decrement() {
    this.quantity--
    this.removeIfNecessary()
  }

  remove() {
    this.order.removeItem(this)
  }

  removeIfNecessary() {
    if (this.quantity <= 0) {
      this.remove()
    }
  }

}