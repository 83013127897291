<template>
  <div class='position-absolute' :style='{bottom: "0", left: "1rem", zIndex: 1000}' v-if='this.toasts.length'>
    <Toast
      v-for='toast, idx in toasts'
      :key='toast.id'
      :icon='toast.icon'
      :icon-class='toast.iconClass'
      :title='toast.title'
      :autoshow='true'
      :delay='5000'
      @hidden='_removeToastAtIndex(idx)'>
      {{ toast.text }}
    </Toast>
  </div>
</template>

<script>

import { Toast } from "../bootstrap"

let id = 1

export default {
  props: [ 'delay' ],
  components: { Toast },
  data() {
    return {
      toasts: []
    }
  },
  methods: {
    successMessage(text) { this._pushMessage(text, { success: true, iconClass: "text-success" }) },
    failureMessage(text) { this._pushMessage(text, { success: false, iconClass: "text-alert" }) },

    _removeToastAtIndex(idx) { this.toasts.splice(idx, 1) },

    _pushMessage(text, options) {
      this.toasts.unshift(this._buildToast(text, options))
    },

    _buildToast(text, options) {
      const icon = options.success ? "check" : "times"

      const { iconClass } = options

      return { id: id++, title: "Cashier", text, icon, iconClass }
    },
  }
}

</script>