<template>
  <div>
    <div class='btn-group w-100'>
      <a class='btn btn-success' href='#' @click.prevent.stop='$emit("reset")'>
        <fa-icon icon='check' />
        {{ translations.finish_order }}
      </a>
      <a class='btn btn-secondary flex-grow-0' :href='collectPaymentUrl'>
        <fa-icon icon='money-bill-wave' />
      </a>
    </div>
  </div>
</template>

<script>

export default {
  props: [ 'order', 'translations' ],
  computed: {
    collectPaymentUrl() { return this.order.collectPaymentUrl }
  }
}

</script>