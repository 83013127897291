<template>
  <div class='d-flex flex-column current-order-drawer'>
    <div class='d-flex justify-content-between'>
      <h4>{{ translations.current_order }}</h4>
      <a
        v-if='clearable'
        href='#'
        class='text-danger'
        @click.prevent.stop='clear'
        >
        {{ translations.clear }}
      </a>
    </div>

    <div class="input-group mb-2">
      <CustomerSelector
        v-model='order.person'
        :disabled='!modifiable'
        :people='people'
        :translations='translations'
        />
      <div class="input-group-append">
        <a
          :href='newPersonUrl'
          class='btn btn-outline-secondary'
          >
          <fa-icon icon='plus'></fa-icon>
        </a>
      </div>
    </div>
    <div class='flex-grow-1 flex-shrink-1 overflow-auto list-group'>
      <OrderItem
        v-for='item in orderItems'
        :key='item.id'
        :item='item'
        :disabled='!modifiable'
      />
      <div class='d-flex flex-grow-1 align-items-center justify-content-center bg-light rounded' v-if='showBlank'>
        <strong class='text-muted'>{{ translations.blank }}</strong>
      </div>
    </div>
    <div class='mt-2'>
      <SubmitButton
        :order='order'
        :translations='translations'
        @submit='$emit("submit")'
        />
    </div>
  </div>
</template>

<script>

import OrderItem from "./order_item"
import SubmitButton from "./current_order/submit_button"
import ContinueButtons from "./current_order/continue_buttons"
import CustomerSelector from "./current_order/customer_selector"

export default {
  props: [ "order", "people", "newPersonUrl", "translations" ],
  components: { OrderItem, CustomerSelector, SubmitButton, ContinueButtons },
  computed: {
    orderItems() { return this.order.items },

    showBlank() { return !this.order.hasItems },

    submitting() { return this.order.submitting },
    duringSubmission() { return this.order.duringSubmission },

    modifiable() { return this.order.modifiable },
    clearable() { return this.order.clearable },
    creatable() { return this.order.creatable },
  },
  methods: {
    clear() {
      if (confirm(this.translations.are_you_sure))
        this.order.clear()
    }
  }
}

</script>