import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { getLocale } from "../utils"

const components = {}

export function start() {
  Vue.use(TurbolinksAdapter)
  Vue.component('fa-icon', FontAwesomeIcon)

  const monetize = (value) => value.toLocaleString(getLocale(), { style: 'currency', currency: 'EUR' })
  Vue.filter("monetize", monetize)
  Vue.filter("monetizeCents", (value) => monetize(value * 0.01))

  loadComponents()
}

function loadComponents() {
  const context = require.context("./apps", true, /\.vue$/)

  context.keys().forEach((key) => {
    const identifier = identifierForKey(key)

    const module = context(key)
    components[identifier] = module.default
  })
}

function identifierForKey(key) {
  return key.replace(/^\.\//, "").replace(/\.vue$/, "")
}

export function refresh() {
  const components = document.querySelectorAll("[data-behavior=vue-component]")

  components.forEach((el) => {
    initializeVueComponent(el)
  })
}

function initializeVueComponent(el) {
  const name = el.dataset.componentName
  const component = components[name]

  if (component) {
    const attrs = extractProps(el.dataset)

    const options = {
      components,
      el: el,
      render: (h) => h(name, {
        attrs
      })
    }

    const instance = new Vue(options)
  }
}

function extractProps(dataset) {
  const props = {}
  Object.keys(dataset).forEach((key) => {
    let value = dataset[key]

    try {
      value = JSON.parse(value)
    } catch (e){
    }


    props[key] = value
  })

  return props
}