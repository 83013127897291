<template>
  <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
      <fa-icon :icon='icon' :class='["mr-2", iconClass]' v-if='hasIcon' />
      <strong class="mr-auto">{{ title }}</strong>
      <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="toast-body">
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  props: [ 'icon', 'title', 'autoshow', 'delay', 'iconClass' ],
  computed: {
    hasIcon() { return this.icon != "" },
    defaultDelay() { return this.delay || 5000 },

    eventHandlerIdentifier() { return `hidden.bs.toast.vue-${this._uid}` },
  },
  mounted() {
    if (this.autoshow) {
      this.show(this.defaultDelay)
    }
    $(this.$el).on(this.eventHandlerIdentifier, () => this.handleHidden())
  },
  beforeDestroy() {
    $(this.$el).off(this.eventHandlerIdentifier)
  },
  methods: {
    show(delay) {
      $(this.$el).toast({ delay }).toast("show")
    },

    // ==================
    // = Event Handling =
    // ==================
    handleHidden() {
      this.$emit("hidden")
    }
  }
}

</script>