export default class Client {
  constructor(url) {
    this.url = url
  }

  get(data) {
    return this.performRequest(data, "GET")
  }

  post(data) {
    return this.performRequest(data, "POST")
  }

  put(data) {
    return this.performRequest(data, "PUT")
  }

  delete(data) {
    return this.performRequest(data, "DELETE")
  }

  performRequest(data, method) {

    return new Promise((resolve, reject) => {
      $.ajax(this.url, {
        method,
        data,
        success: (data, status) => {
          resolve(data)
        },
        error: (xhr, status, error) => {
          reject(error)
        }
      })
    })

  }
}