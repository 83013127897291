<template>
  <div class='row position-relative'>
    <div class='col-8 col-md-9'>
      <Menu
        :menu='menu'
        @add='addOrderItem($event)'
        />
    </div>
    <div class='col-4 col-md-3 h-100'>
      <CurrentOrder
        :order='order'
        :people='peopleRecords'
        :translations='translations'
        :new-person-url='newPersonUrl'
        @submit='submitOrder'
        @reset='resetOrder'
        />
    </div>
    <Messages ref='messages' />
  </div>
</template>

<script>

import Menu from "../components/register/menu"
import Messages from "../components/register/messages"
import CurrentOrder from "../components/register/current_order"
import { Order } from "../models"
import { CreateOrderApi } from "../api"
import { MenuFactory, PersonFactory } from "../factories"

export default {
  props: [ "register", "translations", "createUrl", "newPersonUrl" ],
  data() {
    return {
      order: new Order(),
      registerData: this.register,
    }
  },
  computed: {
    api() { return new CreateOrderApi(this.createUrl) },
    menuFactory() { return new MenuFactory() },
    menu() { return this.menuFactory.createMenuFromProducts(this.registerData.products) },

    personFactory() { return new PersonFactory() },
    peopleRecords() { return this.personFactory.createPeopleFromData(this.registerData.people) },
  },
  components: { Menu, CurrentOrder, Messages },
  methods: {
    addOrderItem(item) {
      this.order.addProduct(item)
    },
    resetOrder() {
      this.order = new Order()
    },
    submitOrder() {
      this.order.submitting = true

      this.api.submit(this.order)
        .then((data) => this.orderSubmissionSuccessful(data) )
        .catch((error) => this.orderSubmissionFailed(error))
    },
    orderSubmissionSuccessful(data) {
      this.order.submitting = false
      this.registerData = data.register
      this.showSuccessMessage(data.flash)

      this.resetOrder()
    },
    orderSubmissionFailed(error) {
      this.order.submitting = false
      this.showErrorMessage(`Submission Error: ${this.order.submissionError}`)
    },

    // ============
    // = Messages =
    // ============
    showSuccessMessage(message) { this.$refs.messages.successMessage(message)  },
    showErrorMessage(message) { this.$refs.messages.failureMessage(message) },
  }
}

</script>

<style lang='scss' scoped>

.current-order-drawer {
  height: calc(100vh - 8.5rem);
}

</style>