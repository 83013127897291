import { Controller } from 'stimulus'
import Rails from "@rails/ujs"
import Sortable from 'sortablejs';

const Selector = {
  ITEM: "*[data-behavior=sortable-item]",
  HANDLE: "*[data-behavior=sortable-handle]",
}

export default class extends Controller {

  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: Selector.HANDLE,
      draggable: Selector.ITEM,
      animation: 150,
      dragClass: "bg-light",
      ghostClass: "bg-info",
      onEnd: (event) => {
        const idx = event.newDraggableIndex
        const item = event.item

        const { sortableUpdateUrl, sortableUpdateParam } = item.dataset
        const data = {}
        data[sortableUpdateParam] = idx

        const url = `${sortableUpdateUrl}?${sortableUpdateParam}=${idx}`

        Rails.ajax({
          type: "PUT",
          url,
        })
      },
    });
  }

  disconnect() {
    if (this.sortable) {
      this.sortable.destroy()
      this.sortable = null
    }
  }

}